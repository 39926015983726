import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Button, Container } from "@material-ui/core";
import { parse } from "query-string";
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";
import axios from "axios";

export default function Index() {
  const { search } = useLocation<any>();
  const history = useHistory();
  const [data, setData] = useState<any>();
  useEffect(() => {
    if (search === undefined) {
      history.push("/learningportal");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { id } = parse(search);
    console.log(id);
    axios
      .get(`/shortclips/${id}`)
      .then(({ data }) => setData(data))
      .catch((error) => console.log(error));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {data && (
        <div>
          <Container>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <h1>{data?.name}</h1>
            </div>
            <Plyr
              source={
                data?.link.includes("youtube") ||
                data?.link.includes("youtu.be")
                  ? {
                      type: "video",
                      sources: [
                        {
                          src: !data?.link.includes("youtu.be")
                            ? data?.link.replace(
                                "https://www.youtube.com/watch?v=",
                                ""
                              )
                            : "k2COPkR25_g",

                          provider: "youtube",
                        },
                      ],
                    }
                  : {
                      type: "video",
                      sources: [
                        {
                          src: data?.link,
                          type: "video/mp4",
                        },
                      ],
                    }
              }
              options={{
                autoplay: true,
                settings: ["captions", "quality", "speed"],
                quality: {
                  default: 720,
                  options: [1080, 720, 576, 480, 360, 240],
                },
              }}
            />
            ]
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                size="large"
                style={{ marginTop: 10, marginBottom: 10 }}
                onClick={() => {
                  window.close();
                }}
              >
                ปิดแท็บ
              </Button>
            </div>
          </Container>
        </div>
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import ClipContainerTemplate from "../../template/ClipContainerTemplate";
import { useHistory, useRouteMatch } from "react-router-dom";

import axios from "axios";
export default function SingleLineGridList(props: { title: string }) {
  const { title } = props;
  const history = useHistory();
  const { path } = useRouteMatch();
  const filterCoursebyCategory = () => {
    history.push(`${path}/shortclips`);
  };
  const Next = () => {
    setTimeout(() => filterCoursebyCategory(), 1000);
  };
  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get("/shortclips")
      .then(({ data }) => setData(data))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {data && (
        <ClipContainerTemplate
          name={title}
          Next={Next}
          isLoadingCourseCategories={isLoading}
          courseCategories={data}
        />
      )}
    </>
  );
}

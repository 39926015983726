import React, { useEffect, useState } from "react";
import axios from "axios";
import ClipAllTemplate from "../../template/ClipAllTemplate";

export default function SingleLineGridList(props: any) {
  const [postsToShow, setPostsToShow] = useState<any>([]);
  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    axios
      .get("/shortclips")
      .then(({ data }) => setData(data))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {data && (
        <ClipAllTemplate
          title={"คลิปสั้นเพื่อการเรียนรู้"}
          postsToShow={postsToShow}
          setPostsToShow={setPostsToShow}
          courseCategories={data}
          isLoadingCourseCategories={isLoading}
        />
      )}
    </>
  );
}

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "assets/css/slide.css";
import "pure-react-carousel/dist/react-carousel.es.css";
import ArrowForward from "@material-ui/icons/ArrowForwardIosRounded";
import ArrowBack from "@material-ui/icons/ArrowBackIosRounded";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ClipDetail from "modules/course/components/Clip/ClipDetail";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    carousel: {
      background: "none",
      border: "none",
      position: "relative",
      padding: theme.spacing(0, 0),
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "calc(100vw - 48px)",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "calc(100vw - 36px)",
      },
    },
    slider: {
      //maxWidth: "1200px",
      outline: "none",
    },
    slide: {
      padding: theme.spacing(0, 0),
      outline: "none !important",
    },
    course: {
      width: "100%",
      padding: "0px",
      height: "100%",
    },
    buttonBack: {
      outline: "none",
      position: "absolute",
      top: "45%",
      left: "-30px",
      background: "none",
      border: "none",
      padding: theme.spacing(0, 0),
    },
    buttonNext: {
      outline: "none",
      position: "absolute",
      top: "45%",
      right: "-30px",
      background: "none",
      border: "none",
      padding: theme.spacing(0, 0),
    },
    container: {
      width: "100%",
    },
  })
);

export default function CourseCarousel(props: any) {
  const classes = useStyles();
  const { detail } = props;
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const data = detail?.filter((item: any) => {
    return item.isShown === true;
  });
  return (
    <>
      {data!.length === 0 ? (
        <h3 style={{ color: "gray" }}>ไม่พบผลการค้นหา</h3>
      ) : (
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={135}
          isIntrinsicHeight
          totalSlides={data!.length}
          visibleSlides={isLgUp ? 4 : isMdUp ? 3 : isSmUp ? 2 : 1}
          step={isLgUp ? 4 : isMdUp ? 3 : isSmUp ? 2 : 1}
          className={classes.carousel}
        >
          <div>
            <Slider className={classes.slider}>
              {data.map((item: any, index: number) => (
                <Slide
                  key={item.name + index}
                  index={index}
                  className={classes.slide}
                >
                  <div className={classes.course}>
                    <ClipDetail
                      id={item.id}
                      link={item.link}
                      organization={item.organization}
                      viewCount={item.viewCount}
                      name={item.name}
                      thumbnail={item.thumbnail}
                    />
                  </div>
                </Slide>
              ))}
            </Slider>

            <ButtonBack className={classes.buttonBack}>
              <ArrowBack />
            </ButtonBack>
            <ButtonNext className={classes.buttonNext}>
              <ArrowForward />
            </ButtonNext>
          </div>
        </CarouselProvider>
      )}
    </>
  );
}

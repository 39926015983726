import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Button } from "@material-ui/core";
import { setCookie, getCookie } from "cookie/cookie";

const useStyles = makeStyles(() => ({
  cookieFooter: {
    position: "fixed",
    bottom: 0,
    left: 0,
    background: "#fefefe",
    width: "100%",
    color: "#1e1e20",
    paddingTop: "20px",
    paddingBottom: "20px",
    boxShadow: "-5px 12px 15px 16px rgba(179,175,175,0.69)",
  },
  button: { borderRadius: "24px" },
  container: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  text: {
    flexGrow: 1,
    marginBottom: "12px",
  },
}));

export default function CookieFooter(props: any) {
  const classes = useStyles();

  const isAcceptCookie = getCookie("AcceptCookie") ?? false;
  const [cookie, setAcceptCookie] = useState(!!isAcceptCookie);

  return !cookie ? (
    <div className={classes.cookieFooter}>
      <Container>
        <div className={classes.container}>
          <div className={classes.text}>
            <div>
              เราใช้คุกกี้เพื่อพัฒนาประสิทธิภาพ
              และประสบการณ์ที่ดีในการใช้เว็บไซต์ของคุณ
            </div>
            <div>
              คุณสามารถศึกษารายละเอียดได้ที่{" "}
              <a href="https://www.ocsc.go.th/cookies-policy">นโยบายคุ้กกี้</a>
            </div>
          </div>

          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={() => {
              setAcceptCookie(true);
              setCookie(
                "AcceptCookie",
                "true; Domain=.ocsc.go.th",
                new Date().getTime() + 10 * 365 * 24 * 60 * 60
              );
            }}
          >
            ยอมรับ
          </Button>
        </div>
      </Container>
    </div>
  ) : (
    <></>
  );
}

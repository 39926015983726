import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import numberFormat from "utils/numberFormat";
import { Theme } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import axios from "axios";

const useStyles = makeStyles((theme: Theme) => ({
  gridPlatform: {
    marginTop: 8,
    marginBottom: 8,
  },
  root: {
    borderRadius: "0.5rem",
    padding: 10,
    "&:hover": {
      background: "#cccccc8a",
    },
  },

  card: {
    width: "100%",
    minHeight: "320px",
    borderRadius: "0.5rem",
    [theme.breakpoints.up("sm")]: {
      transition: "0.4s",
      "&:hover": {
        transform: "translateY(-4px)",
      },
    },
    marginBottom: 4,
  },
  main: {
    overflow: "hidden",
    borderTopLeftRadius: "0.5rem",
    borderTopRightRadius: "0.5rem",
    zIndex: 1,
  },

  avatar: {
    width: 48,
    height: 48,
  },
  tag: {
    display: "inline-block",
    fontFamily: "'Sen', sans-serif",
    backgroundColor: "#ff5dac",
    borderRadius: "0.5rem",
    padding: "2px 0.5rem",
    color: "#fff",
    marginBottom: "0.5rem",
  },
  title: {
    marginTop: 4,
    maxWidth: "280px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    fontSize: "1.2rem",
    fontWeight: 600,
    color: "#132740",
    paddingLeft: 8,
    marginRight: 8,
  },
  author: {
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    fontSize: "14px",
    fontWeight: 600,
    color: "#132740",
    padding: 3,
    maxWidth: 190,
    marginLeft: 8,
    marginBottom: 2,
  },
  subtitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    fontSize: "1rem",
    fontWeight: 500,
    color: "#132740",
    paddingLeft: 8,
    marginRight: 8,
    marginBottom: 10,
  },
  box: {
    padding: 8,
    marginLeft: 6,
  },
  view: {
    marginRight: 10,
  },
  dot: {
    color: theme.palette.secondary.main,
  },
  rating: {
    paddingLeft: 8,
  },
  logo: {
    margin: 8,
  },
  caption: {
    marginTop: 8,
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    fontSize: "12px",
    color: "#434a54",
  },
  detail: {
    marginBottom: 18,
  },
  submit: {
    padding: 0,
    float: "right",
    width: "100%",
    margin: 0,
  },
  boxshadow: {
    marginTop: 30,
    borderRadius: 10,
  },
  category: {
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    maxWidth: "200px",
  },
  cardMedia: {
    paddingTop: "75%", // 4:3
    borderRadius: theme.shape.borderRadius,
  },
}));

export default function CurriculumDetailCard(props: any) {
  const { id, viewCount, organization, thumbnail, name, link } = props;
  const classes = useStyles();
  const onOpen = () => {
    axios
      .put(`/shortclips/${id}/viewcount`)
      .then(() => {
        if (
          link.includes("mp4") ||
          link.includes("youtube") ||
          link.includes("youtu.be")
        ) {
          window.open(`/learningportal/clip?id=${id}`);
        } else {
          window.open(link);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className={classes.root}>
      <div className={classes.card} onClick={onOpen}>
        <div className={classes.boxshadow}>
          <CardMedia
            style={{
              backgroundImage: `url('${thumbnail}')`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
            image={thumbnail}
            className={classes.cardMedia}
            title={name}
          />
        </div>
        <div className={classes.title}>{name}</div>
        <div className={classes.subtitle}>{organization}</div>
        <Typography
          variant="caption"
          align="left"
          component="span"
          color={"textSecondary"}
          style={{ marginLeft: 10 }}
        >
          การดู {numberFormat(viewCount)} ครั้ง
        </Typography>
      </div>
    </div>
  );
}

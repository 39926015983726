import React from "react";

export default function componentName() {
  return (
    <div
      style={{
        background: "#fff",
        width: "100%",
        paddingTop: 400,
        height: "100vh",
      }}
    ></div>
  );
}

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { Box, Modal, IconButton, Button } from "@material-ui/core";
import { Close, Announcement } from "@material-ui/icons";
import { getCookie } from "cookie/cookie";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 320,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #222222",
    boxShadow: "24px",
    padding: 16,
  },
}));

export default function CustomizedDialogs() {
  const classes = useStyles();
  const [message, setMessage] = React.useState<string | undefined>();
  const handleOpen = () => {
    setOpen(true);
  };
  const token = getCookie("token");
  useEffect(() => {
    axios.get("/pressreleases/0").then(({ data }) => {
      setMessage(data.mesg);
      data.mesg !== "" && !token && handleOpen();
    });
  }, [token]);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box className={classes.modal}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <div className={classes.title}>
            <Announcement color="secondary" style={{ marginRight: "12px" }} />
            ประกาศ
          </div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          mt={4}
          fontSize={16}
          mb={4}
        >
          {message && (
            <div
              dangerouslySetInnerHTML={{
                __html: message,
              }}
            />
          )}
        </Box>

        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={handleClose}
        >
          รับทราบ
        </Button>
      </Box>
    </Modal>
  );
}

import axios from "axios";
import * as infoactions from "modules/infomation/actions";

const LOAD_FORGOT_REQUEST = "learning-portal/src/ui/LOAD_FORGOT_REQUEST";
const LOAD_FORGOT_SUCCESS = "learning-portal/src/ui/LOAD_FORGOT_SUCCESS";
const LOAD_FORGOT_FAILURE = "learning-portal/src/ui/LOAD_FORGOT_REQUEST";

function loadFORGOT(updateInfo: any, id: string, type: number) {
  return async (dispatch: any) => {
    dispatch({ type: LOAD_FORGOT_REQUEST });
    try {
      const data = await axios.put(
        `/users/${id}/forgetpassword${type}`,
        updateInfo,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const action = infoactions.loadMessage(`${data.data.mesg}`, "success");

      dispatch(action);
      dispatch({
        type: LOAD_FORGOT_SUCCESS,
        payload: {
          message: `${data.data?.mesg}`,
          data: data.data,
          status: data.status,
        },
      });
    } catch (err) {
      dispatch({
        type: LOAD_FORGOT_FAILURE,
        payload: {
          message: "เกิดข้อผิดพลาด",
          staus: err.response.status,
        },
      });

      const action = infoactions.loadMessage(
        `เกิดข้อผิดพลาด ${
          err.response.data.mesg ? err.response.data?.mesg : ""
        }`,
        "error"
      );
      dispatch(action);
    }
  };
}

export {
  LOAD_FORGOT_REQUEST,
  LOAD_FORGOT_SUCCESS,
  LOAD_FORGOT_FAILURE,
  loadFORGOT,
};

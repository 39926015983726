import React from "react";
import SignupRoutes from "modules/signup/page";

import LoginRoutes from "modules/login/page";
import ResetRoutes from "modules/reset/page";
import HistoryRoutes from "modules/history/page";
import ForgetRoutes from "modules/forgetPassword/page";
import Edit from "modules/editProfile/page";
import FAQ from "modules/F&A/page";
import Page404 from "modules/404page/page/404";
import { Redirect, Route, Switch } from "react-router-dom";
import Scroll from "./Scroll";
import PrivateRoute from "auth/PrivateRouter";
import PublicLoginRouter from "auth/PublicLoginRouter";
import { Toolbar } from "@material-ui/core";
import Course from "modules/course/page";
import GroupCurriculum from "modules/course/page/GroupCurriculum/GroupCurriculum";
import GroupReccomment from "modules/course/page/GroupReccomment";
import GroupTopRate from "modules/course/page/GroupTopRate";
import GroupCourse from "modules/course/page/GroupCourse/GroupCourse";
import GroupClip from "modules/course/page/GroupClip";
import GroupAllCourse from "modules/course/page/GroupAllCourse/GroupAllCourse";
import Search from "modules/course/page/Search";
import Clip from "modules/Clip";
export default function Router() {
  return (
    <React.Fragment>
      <Toolbar />
      <Scroll />
      <Switch>
        <Redirect
          exact
          from={`/learningportal/community/plugins/comments`}
          to={`/learningportal`}
        />
        <Route exact path={`/learningportal/FAQ`} component={FAQ} />
        <PrivateRoute exact path={`/learningportal/edit`} component={Edit} />
        <PrivateRoute
          exact
          path={`/learningportal/history`}
          component={HistoryRoutes}
        />
        <PrivateRoute
          exact
          path={`/learningportal/reset`}
          component={ResetRoutes}
        />

        <Route exact path={`/learningportal/signup`} component={SignupRoutes} />
        <PublicLoginRouter
          exact
          path={`/learningportal/login`}
          component={LoginRoutes}
        />
        <Route exact path={`/learningportal/clip`} component={Clip} />
        <Route
          exact
          path={`/learningportal/shortclips`}
          component={GroupClip}
        />

        <Route exact path={`/learningportal/forget`} component={ForgetRoutes} />
        <Route exact path={`/learningportal/search`} component={Search} />
        <Route
          exact
          path={`/learningportal/curriculum`}
          component={GroupCurriculum}
        />
        <Route
          exact
          path={`/learningportal/courses/recommend`}
          component={GroupReccomment}
        />
        <Route
          exact
          path={`/learningportal/courses/toprate`}
          component={GroupTopRate}
        />
        <Route
          exact
          path={"/learningportal/courses"}
          component={GroupAllCourse}
        />

        <Route exact path="/learningportal/course" component={GroupCourse} />
        <Route exact path="/learningportal" component={Course} />
        <Route exact path="*" component={Page404} />
      </Switch>
    </React.Fragment>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";

import CourseCategoryTemplate from "../../template/CourseCategoryTemplate";

export default function GroupCourse() {
  const [loading, setLoading] = useState(true);
  const [course, setCouse] = useState<any>();
  const [postsToShow, setPostsToShow] = useState<any>([]);

  useEffect(() => {
    axios
      .get(`/Courses/TopRated?max=1000`)
      .then(({ data }) => {
        setCouse(data);
      })
      .finally(() => setLoading(false));
    setPostsToShow([]);

    // eslint-disable-next-line
  }, []);
  return (
    <CourseCategoryTemplate
      title="อันดับยอดฮิต"
      postsToShow={postsToShow}
      setPostsToShow={setPostsToShow}
      courseCategories={course ? course : []}
      isLoadingCourseCategories={loading}
      isCurriculum={false}
      selectCategory={false}
    />
  );
}

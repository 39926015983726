import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, useMediaQuery, Container } from "@material-ui/core";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  footer: {
    background: "#1959bd",
    color: "#f3f3fb",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  ocsc: {
    textAlign: "center",
    color: theme.palette.primary.light,
    fontWeight: 500,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  color: {
    textAlign: "center",
    color: `${theme.palette.secondary.light}`,
    fontWeight: 500,
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  color1: {
    textAlign: "center",
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.primary.light,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
}));

export default function PersistentDrawerLeft(props: any) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:1060px)");
  const [footer, setFooter] = useState({ value1: "", value2: "" });
  useEffect(() => {
    axios.get("/constants/phonenumber").then(({ data }) => setFooter(data));
  }, []);
  return (
    <div className={classes.footer}>
      <Container>
        <Grid
          container
          direction="row"
          justify={matches ? "space-around" : "center"}
          alignItems="center"
        >
          <Grid item xs={12} lg={6}>
            <div className={classes.ocsc}>
              สำนักงานคณะกรรมการข้าราชการพลเรือน (สำนักงาน ก.พ.)
            </div>
            <div className={classes.color}>
              <a href="https://www.ocsc.go.th" target="blank">
                Copyright © 2022 Office of the Civil Service Commission (OCSC)
              </a>
            </div>
          </Grid>

          <div className={classes.color1}>
            47/111 หมู่ 4 ถนนติวานนท์ ตำบลตลาดขวัญ อำเภอเมือง จังหวัดนนทบุรี
            11000
            <div>
              <a
                className={classes.color1}
                href="mailto:learningspace@ocsc.go.th"
              >
                E-mail : learningspace@ocsc.go.th
              </a>
            </div>
            {footer && (
              <div className={classes.color1}>
                <div
                  dangerouslySetInnerHTML={{ __html: ` ${footer.value1}` }}
                />
                <br />
                {footer && footer?.value2 && footer?.value2 !== null && (
                  <div
                    className={classes.color}
                    dangerouslySetInnerHTML={{ __html: ` ${footer.value2}` }}
                  />
                )}
              </div>
            )}
          </div>
        </Grid>
      </Container>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import * as actions from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { Toolbar, Grid, CircularProgress } from "@material-ui/core";
import Snackbar from "modules/compoenent/atomic/SnackBar";
import Edit from "../components/Edit";
import * as actionsInfo from "modules/infomation/actions";

export default function Page() {
  const dispatch = useDispatch();
  const { message, severity } = useSelector((state: any) => state.infomation);
  const [isSubmit, setIssubmit] = useState(false);
  useEffect(() => {
    const actionProfile = actions.loadGetProfile();
    dispatch(actionProfile);
    const action = actionsInfo.loadOccupations();
    dispatch(action);
    // eslint-disable-next-line
  }, [isSubmit]);

  const { data } = useSelector((state: any) => state.edit);
  useEffect(() => {
    if (severity === "success") {
      setIssubmit(!isSubmit);
    }
    // eslint-disable-next-line
  }, [severity]);

  return (
    <>
      {data.title !== undefined ? (
        <>
          {message !== null && (
            <Snackbar
              message={message}
              open={message !== null ? true : false}
              severity={severity}
            />
          )}
          <Toolbar />
          <Edit
            data={data}
            severity={severity}
            setIssubmit={setIssubmit}
            isSubmit={isSubmit}
          />
        </>
      ) : (
        <Grid container direction="row" justify="center" alignItems="center">
          <CircularProgress color="secondary" />
        </Grid>
      )}
    </>
  );
}

import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowForwardIos } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { CardProps, CardRecommentProps } from "./typescript";

import CardRecProps from "./CardRecProps";
const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: "10px",
    width: "95%",
    borderRadius: 16,
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  header: {
    fontFamily: "Barlow, san-serif",
  },
  headline: {
    color: theme.palette.primary.dark,
    fontSize: "1.25rem",
    fontWeight: 600,
  },
  more: {
    color: "#299eea",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    cursor: "pointer",
  },
}));

export default function SocialCard(
  props: CardRecommentProps & { path: string }
) {
  const { title, data, path } = props;
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Grid className={classes.card}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems={"center"}
          className={classes.header}
        >
          <Grid className={classes.headline}>{title}</Grid>
          <Grid
            className={classes.more}
            onClick={() => history.push(`/${path}`)}
          >
            ดูเพิ่มเติม <ArrowForwardIos fontSize="small" />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          {data.map((item: CardProps, index: number) => (
            <CardRecProps data={item} key={index} />
          ))}
        </Grid>
      </Grid>
    </>
  );
}

import React, { useEffect, useState } from "react";
import {
  Grid,
  Divider,
  Container,
  CircularProgress,
  Button,
  TextField,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Header from "modules/course/share/Header";
import ClipDetail from "modules/course/components/Clip/ClipDetail";

import { Search } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingRight: "8vw",
    paddingLeft: "8vw",
  },
  divider: {
    marginBottom: 20,
  },
  circular: {
    marginTop: 20,
  },
  button: {
    background: theme.palette.primary.dark,
    borderRadius: 40,
    padding: "15px 30px 15px 30px",
    margin: 10,
  },
  input: {
    color: "#0f1626",
    background: "white",
    fontWeight: 600,
    borderRadius: 100,
    "& .MuiInputBase-root.MuiOutlineInput-root": {
      color: "#45A29E",
      borderColor: "#757575",
      fontWeight: 600,
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      padding: "0 !important",
    },
  },
}));

export default function CourseCategoryTemplate({
  title,
  courseCategories,
  isLoadingCourseCategories,
  postsToShow,
  setPostsToShow,
}: {
  title: any;
  courseCategories: any;
  isLoadingCourseCategories: any;
  postsToShow: any;
  setPostsToShow: any;
}) {
  const classes = useStyles();
  const postsPerPage = 8;
  const [next, setNext] = useState(8);
  //           item.isShown &&
  const [value, setValue] = useState("");
  const handlerSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };
  const loopWithSlice = (start: any, end: any) => {
    const slicedPosts = courseCategories
      .filter(
        (item: any) =>
          item.name.toLowerCase().includes(value) ||
          item.organization.toLowerCase().includes(value)
      )
      .filter((item: any) => item.isShown)
      .slice(start, end);

    setPostsToShow([...postsToShow, ...slicedPosts]);
  };
  useEffect(() => {
    setPostsToShow([]);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!isLoadingCourseCategories) {
      loopWithSlice(0, postsPerPage);
    }

    // eslint-disable-next-line
  }, [isLoadingCourseCategories]);

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage);
    setNext(next + postsPerPage);
  };
  return (
    <>
      <Header text={""} />
      <div>
        <Grid
          container
          className={classes.content}
          direction="row"
          alignItems="center"
          justify={"space-between"}
        >
          <h2>{title}</h2>
        </Grid>

        <Divider style={{ marginBottom: 20 }} />

        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            alignItems="center"
            justify={"center"}
          >
            <TextField
              label="ค้นหาคลิปสั้นเพื่อการเรียนรู้"
              name="userId"
              variant="outlined"
              value={value || ""}
              onChange={handlerSearch}
              InputProps={{
                className: classes.input,
                endAdornment: (
                  <IconButton color="secondary" aria-label="search">
                    <Search />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify={isLoadingCourseCategories ? "center" : "flex-start"}
            spacing={3}
          >
            {isLoadingCourseCategories && (
              <CircularProgress
                color="secondary"
                className={classes.circular}
              />
            )}

            {postsToShow
              .filter(
                (item: any) =>
                  item.name.toLowerCase().includes(value) ||
                  item.organization.toLowerCase().includes(value)
              )
              .map((item: any, index: number) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={item.name}>
                  <ClipDetail
                    id={item.id}
                    link={item.link}
                    organization={item.organization}
                    viewCount={item.viewCount}
                    name={item.name}
                    thumbnail={item.thumbnail}
                  />
                </Grid>
              ))}
          </Grid>
          {postsToShow.filter(
            (item: any) =>
              item.name.toLowerCase().includes(value) ||
              item.organization.toLowerCase().includes(value)
          ).length === 0 && (
            <Grid
              container
              direction="row"
              alignItems="center"
              justify={"center"}
              style={{ marginTop: 10 }}
            >
              <h2>หาคลิปไม่พบ</h2>
            </Grid>
          )}
          {!isLoadingCourseCategories && next < courseCategories.length && (
            <Grid
              container
              direction="row"
              alignItems="center"
              justify={"center"}
              spacing={3}
            >
              <Button
                onClick={handleShowMorePosts}
                color="secondary"
                className={classes.button}
              >
                ดูเพิ่มเติม
              </Button>
            </Grid>
          )}
        </Container>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { TextField, MenuItem, FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as actions from "modules/infomation/actions";
import { useDispatch, useSelector } from "react-redux";
import { DateThPicker } from "modules/compoenent/atomic/DatePicker";
import { jobTypes1Props, jobLevelProps, typeProps } from "../typescript";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  input: {
    "& .MuiInputBase-root.MuiOutlineInput-root": {
      color: "#45A29E",
      borderColor: "#757575",
      fontWeight: 600,
    },
  },
  selectInput: {
    "& .MuiInputBase-root.MuiOutlineInput-root": {
      color: "#45A29E",
      borderColor: "#757575",
      fontWeight: 600,
    },
  },
  textfield: {
    marginTop: 10,
  },
  menu: {
    fontSize: 12,
    whiteSpace: "normal",
    [theme.breakpoints.up("sm")]: {
      fontSize: 14,
    },
  },
}));

export default function Type1(props: typeProps) {
  const classes = useStyles();
  const { formProps, name } = props;
  const dispatch = useDispatch();
  const { jobTypes1, jobLevels } = useSelector(
    (state: any) => state.infomation
  );
  const [position, setPosition] = useState([]);
  useEffect(() => {
    const action = actions.loadJobType1();
    dispatch(action);
    const actionJobLevel = actions.loadJobLevel();
    dispatch(actionJobLevel);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const action = actions.loadMinistries();
    dispatch(action);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = formProps.getValues("MinistryId");
  const valueDepartmentId = formProps.getValues("DepartmentId");

  useEffect(() => {
    const value = formProps.getValues("MinistryId");

    if (value !== undefined && value !== "") {
      const actionDepartments = actions.loadDepartments(value);
      dispatch(actionDepartments);
    }
    formProps.setValue("DepartmentId", undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    axios.get("/constants/position").then(({ data }) => {
      setPosition(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formProps.setValue("jobTitle", undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueDepartmentId]);

  const { Ministries, Departments } = useSelector(
    (state: any) => state.infomation
  );

  return (
    <>
      <FormControl fullWidth>
        <h4> {name}</h4>
        <DateThPicker
          title={"วันที่รับราชการ"}
          register={formProps.register}
          name={"jobStartDate"}
        />
        <Controller
          as={
            <TextField
              variant="outlined"
              fullWidth
              className={classes.textfield}
              label="ประเภทตำแหน่ง"
              InputLabelProps={{
                className: classes.selectInput,
              }}
              select
              helperText={
                formProps.errors.jobtypeId && formProps.errors.jobtypeId.message
              }
              error={!!formProps.errors.jobtypeId}
              defaultValue=""
            >
              {jobTypes1.map((jobType1: jobTypes1Props, index: number) => (
                <MenuItem key={index} value={jobType1.id}>
                  {jobType1.name}
                </MenuItem>
              ))}
            </TextField>
          }
          name="jobtypeId"
          rules={{ required: "กรุณาเลือกประเภทตำแหน่ง" }}
          control={formProps.control}
          defaultValue=""
        />

        <Controller
          as={
            <TextField
              variant="outlined"
              fullWidth
              defaultValue=""
              className={classes.textfield}
              label="กระทรวง"
              InputLabelProps={{
                className: classes.selectInput,
              }}
              select
              helperText={
                formProps.errors.MinistryId &&
                formProps.errors.MinistryId.message
              }
              error={!!formProps.errors.MinistryId}
            >
              {Ministries.map((ministry: any, index: number) => (
                <MenuItem
                  className={classes.menu}
                  key={index}
                  value={ministry.id}
                >
                  {ministry.name}
                </MenuItem>
              ))}
            </TextField>
          }
          name="MinistryId"
          rules={{ required: "กรุณาเลือกประเภทตำแหน่ง" }}
          control={formProps.control}
          defaultValue=""
        />

        <Controller
          as={
            <TextField
              variant="outlined"
              fullWidth
              defaultValue=""
              className={classes.textfield}
              label="กรมต้นสังกัด"
              InputLabelProps={{
                className: classes.selectInput,
              }}
              select
              helperText={
                formProps.errors.DepartmentId &&
                formProps.errors.DepartmentId.message
              }
              error={!!formProps.errors.DepartmentId}
            >
              {Departments.map((department: any, index: number) => (
                <MenuItem
                  className={classes.menu}
                  key={index}
                  value={department.id}
                >
                  {department.name}
                </MenuItem>
              ))}
            </TextField>
          }
          name="DepartmentId"
          rules={{ required: "กรุณาเลือกกรมที่สังกัด" }}
          control={formProps.control}
          defaultValue=""
        />
        <Controller
          as={
            <TextField
              fullWidth
              label="ตำแหน่ง"
              select
              name="jobTitle"
              inputRef={formProps.register}
              variant="outlined"
              className={classes.textfield}
              InputProps={{
                className: classes.selectInput,
              }}
              helperText={
                formProps.errors.jobTitle && formProps.errors.jobTitle.message
              }
              error={!!formProps.errors.jobTitle}
              defaultValue=""
            >
              {position &&
                position
                  ?.filter(
                    (item: any) =>
                      item.ministryId === formProps.watch("MinistryId") &&
                      item.departmentId === formProps.watch("DepartmentId")
                  )
                  .map((item: any, index: number) => (
                    <MenuItem
                      className={classes.menu}
                      key={index}
                      value={item.name}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
            </TextField>
          }
          name="jobTitle"
          rules={{ required: "กรุณาเลือตำแหน่ง" }}
          control={formProps.control}
          defaultValue=""
        />

        <Controller
          as={
            <TextField
              variant="outlined"
              fullWidth
              className={classes.textfield}
              label="ระดับ"
              InputLabelProps={{
                className: classes.selectInput,
              }}
              select
              helperText={
                formProps.errors.jobLevelid &&
                formProps.errors.jobLevelid.message
              }
              error={!!formProps.errors.jobLevelid}
              defaultValue=""
            >
              {jobLevels.map((jobLevel: jobLevelProps, index: number) => {
                if (
                  formProps.getValues("jobtypeId") === 1 &&
                  jobLevel.id > 0 &&
                  jobLevel.id < 5
                ) {
                  return (
                    <MenuItem key={index} value={jobLevel.id}>
                      {jobLevel.name}
                    </MenuItem>
                  );
                }
                if (
                  formProps.getValues("jobtypeId") === 2 &&
                  jobLevel.id > 4 &&
                  jobLevel.id < 10
                ) {
                  return (
                    <MenuItem key={index} value={jobLevel.id}>
                      {jobLevel.name}
                    </MenuItem>
                  );
                }
                if (formProps.getValues("jobtypeId") >= 3 && jobLevel.id > 9) {
                  return (
                    <MenuItem key={index} value={jobLevel.id}>
                      {jobLevel.name}
                    </MenuItem>
                  );
                }
                return "";
              })}
            </TextField>
          }
          name="jobLevelid"
          rules={{ required: "กรุณาเลือกระดับ" }}
          control={formProps.control}
          defaultValue=""
        />

        <TextField
          fullWidth
          label="ชื่อส่วนราชการที่สังกัด"
          name="Division"
          defaultValue=""
          variant="outlined"
          className={classes.textfield}
          InputProps={{
            className: classes.input,
          }}
          inputRef={formProps.register}
          helperText={
            formProps.errors.Division ? formProps.errors.Division.message : ""
          }
          error={!!formProps.errors.Division}
        />
      </FormControl>
    </>
  );
}
